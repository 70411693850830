import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Button, Table, Badge } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import DatePicker from "react-datepicker";
import PermissionGate from '../PermissionGate';

const ChannelDetails = () => {
    const { background } = useContext(ThemeContext);
    const [selectedFeedOptions, setSelectedFeedOptions] = useState([]);
    const [selectedChannelOptions, setSelectedChannelOptions] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState('day');
    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Export to CSV');
    const [feedOptionList, setFeedOptionList] = useState([]);
    const [feedOptionListAll, setFeedOptionListAll] = useState([]);
    const [channelOptionList, setChannelOptionList] = useState([]);
    const [selectedDateRangeOptions, setSelectedDateRangeOptions] = useState('last_7days_minus_2');
    const [selectedParameter, setselectedParameter] = useState('revenue');
    const [selectedParameter2, setselectedParameter2] = useState('rpm');
    const [tableData, setTableData] = useState([]);
    const [tableDataTotal, setTableDataTotal] = useState([]);
    const [dateRangeStart, setDateRangeStart] = useState(new Date().toISOString().split('T')[0]);
    const [dateRangeEnd, setDateRangeEnd] = useState(new Date().toISOString().split('T')[0]);
    const [allResponseData, setAllResponseData] = useState({});
    const [chartLabels, setChartLabels] = useState([]);
    const [chartLabels2, setChartLabels2] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [chartHeight, setChartHeight] = useState(20);
    
    const [chartDataset, setChartDataset] = useState([]);
    const [chartDataset2, setChartDataset2] = useState([]);
    const [chartColors, setChartColors] = useState([]);
    const [chartColors2, setChartColors2] = useState([]);
    const [sortBy, setSortBy] = useState('asc');
    const [expandCollapseText, setExpandCollapseText] = useState('Expand All');
    const [loadingBadge, setLoadingBadge] = useState(false);

    const [advOptionList, setAdvOptionList] = useState([]);
    const [selectedAdvOptions, setSelectedAdvOptions] = useState([]);
    
    const [expandCollapseTable, setExpandCollapseTable] = useState('Expand Table');
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        getAdvOptionList();
        getFeedOptionList();
    }, []);

    // Multi-Select Conf - START //
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
        return (<div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props)}><div style={{ padding: "6px 5px" }} id="clear_selected_channel">{children}</div></div>);
    };
    const ClearIndicatorStyles = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "black" : "black", minHeight: "50px" });
    const ClearIndicatorStylesDark = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "white" : "white", minHeight: "50px" });
    // Multi-Select Conf - END //

    // Charts - START //
    const chartDataMain = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                data: chartData
            },
        ],
    };

    const chartDataMain2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors2,
                hoverBackgroundColor: chartColors2,
                data: chartData2
            },
        ],
    };

    const chartDataChannel = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                data: chartData
            },
        ],
    };

    const chartDataChannel2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors2,
                hoverBackgroundColor: chartColors2,
                data: chartData2
            },
        ],
    };
    
    const chartOptionsMain = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltips: { enabled: true, mode: "index", intersect: false },
            responsive: true,
            datalabels: {
                display: true,
                color: `${checkDatalabelColor()}`,
                anchor: "end",
                offset: -20,
                align: "start"
              }
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartDataDay = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: chartDataset
    };

    const chartDataDay2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: chartDataset2
    };

    const chartOptionsDay = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        if(selectedParameter !== 'bs_rate_us') {
                            return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                        } else {
                            return '';
                        }
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            if(selectedParameter !== 'bs_rate_us') {
                                return items.dataset.label + ': ' + items.raw;
                            } else {
                                return items.dataset.label + ': ' + items.raw + '%';
                            }
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter === 'rpm' || selectedParameter === 'cpc' || selectedParameter === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };
    const chartOptionsDay2 = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.label + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter2 === 'rpm' || selectedParameter2 === 'cpc' || selectedParameter2 === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    function checkDatalabelColor() {
        if(background.value === 'dark') {
            return "white";
        } else {
            return "black";
        }
    }
    // Charts - END //

    function handleSelectFeed(data) {
        setSelectedFeedOptions([data]);
        getChannelOptionList(data.value);
        setChannelOptionList([]);
        setSelectedChannelOptions([]);
    }

    function handleSelectChannel(data) {
        setSelectedChannelOptions(data);
    }
    
    function handleSelectDateRange(event) {
        setSelectedDateRangeOptions(event);
    }

    function handleSelectReportType(event) {
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);
        setSelectedReportType(event);
    }

    function handleSelectAdv(data) {
        if(data.length > 0) {
            var feed_list = [];
            for(const c of feedOptionListAll) {
                for(const d of data) {
                    if(c.advertiser_id === d.id) {
                        feed_list.push(c);
                    }
                }
            }
            setFeedOptionList(feed_list);
        } else {
            setFeedOptionList(feedOptionListAll);
        }

        setSelectedAdvOptions(data);
    }

    async function getAdvOptionList() {
        const response = await axios.get(baseURL+'/api/get_advertiser_list?include_all=no')
        setAdvOptionList(response.data);
    }

    async function getFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_feed_list?include_all=no')
        setFeedOptionListAll(response.data);
        setFeedOptionList(response.data);
    }
    async function getChannelOptionList(feed_name) {
        var data = {
            feed_name: feed_name
        }
        const response = await axios.post(baseURL+'/api/get_channel_list', data)
        setChannelOptionList(response.data);
    }

    async function getAdvertiserReport() {
        setLoadingBadge(true);
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);

        var data = {
            feeds: selectedFeedOptions,
            channels: selectedChannelOptions,
            date: selectedDateRangeOptions,
            date_range_start: dateRangeStart,
            date_range_end: dateRangeEnd
        }
        var api_url = '';
        if(selectedReportType === 'day') {
            api_url = '/api/channel_report/by_day';
        } else if(selectedReportType === 'channel') {
            api_url = '/api/channel_report/by_channel';
        } else if(selectedReportType === 'country') {
            api_url = '/api/channel_report/by_country';
        }
        
        const response = await axios.post(baseURL+api_url, data)
        
        console.log(response.data);

        setAllResponseData(response.data);
        setTableData(response.data.table);
        setTableDataTotal(response.data.total);

        setChartHeight(100);

        if(selectedReportType === 'day') {
            setChartDay(response.data, selectedParameter);
            setChartDay2(response.data, selectedParameter2);
        } else {
            setChartColors(response.data.chart_color);
            setChart(response.data, selectedParameter);

            setChartColors2(response.data.chart_color);
            setChart2(response.data, selectedParameter2);
        }
        setLoadingBadge(false);
    }

    function handleSelectParameter(event) {
        setselectedParameter(event);
        if(selectedReportType === 'day') {
            setChartDay(allResponseData, event);
        } else {
            setChart(allResponseData, event);
        }
    }

    function handleSelectParameter2(event) {
        setselectedParameter2(event);
        if(selectedReportType === 'day') {
            setChartDay2(allResponseData, event);
        } else {
            setChart2(allResponseData, event);
        }
    }

    async function setChartDay(data, sp) {
        setChartLabels(data.labels);
        if(sp === 'revenue') {
            setChartDataset(data.datasets.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartDataset(data.datasets.pub_rev);
        }
        if(sp === 'impressions') {
            setChartDataset(data.datasets.impressions);
        }
        if(sp === 'searches') {
            setChartDataset(data.datasets.searches);
        }
        if(sp === 'clicks') {
            setChartDataset(data.datasets.clicks);
        }
        if(sp === 'rpm') {
            setChartDataset(data.datasets.rpm);
        }
        if(sp === 'cpc') {
            setChartDataset(data.datasets.cpc);
        }
        if(sp === 'ctr') {
            setChartDataset(data.datasets.ctr);
        }
        if(sp === 'bs_rate_us') {
            setChartDataset(data.datasets.bs_rate_us);
        }
    }

    async function setChartDay2(data, sp) {
        setChartLabels2(data.labels);
        if(sp === 'revenue') {
            setChartDataset2(data.datasets.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartDataset2(data.datasets.pub_rev);
        }
        if(sp === 'impressions') {
            setChartDataset2(data.datasets.impressions);
        }
        if(sp === 'searches') {
            setChartDataset2(data.datasets.searches);
        }
        if(sp === 'clicks') {
            setChartDataset2(data.datasets.clicks);
        }
        if(sp === 'rpm') {
            setChartDataset2(data.datasets.rpm);
        }
        if(sp === 'cpc') {
            setChartDataset2(data.datasets.cpc);
        }
        if(sp === 'ctr') {
            setChartDataset2(data.datasets.ctr);
        }
        if(sp === 'bs_rate_us') {
            setChartDataset2(data.datasets.bs_rate_us);
        }
    }

    async function setChart(data, sp) {
        setChartLabels(data.chart_data.labels);
        if(sp === 'revenue') {
            setChartData(data.chart_data.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartData(data.chart_data.pub_rev);
        }
        if(sp === 'impressions') {
            setChartData(data.chart_data.impressions);
        }
        if(sp === 'searches') {
            setChartData(data.chart_data.searches);
        }
        if(sp === 'clicks') {
            setChartData(data.chart_data.clicks);
        }
        if(sp === 'rpm') {
            setChartData(data.chart_data.rpm);
        }
        if(sp === 'cpc') {
            setChartData(data.chart_data.cpc);
        }
        if(sp === 'ctr') {
            setChartData(data.chart_data.ctr);
        }
        if(sp === 'bs_rate_us') {
            setChartData(data.chart_data.bs_rate_us);
        }
    }

    async function setChart2(data, sp) {
        setChartLabels2(data.chart_data.labels);
        if(sp === 'revenue') {
            setChartData2(data.chart_data.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartData2(data.chart_data.pub_rev);
        }
        if(sp === 'impressions') {
            setChartData2(data.chart_data.impressions);
        }
        if(sp === 'searches') {
            setChartData2(data.chart_data.searches);
        }
        if(sp === 'clicks') {
            setChartData2(data.chart_data.clicks);
        }
        if(sp === 'rpm') {
            setChartData2(data.chart_data.rpm);
        }
        if(sp === 'cpc') {
            setChartData2(data.chart_data.cpc);
        }
        if(sp === 'ctr') {
            setChartData2(data.chart_data.ctr);
        }
        if(sp === 'bs_rate_us') {
            setChartData2(data.chart_data.bs_rate_us);
        }
    }

    async function openAllFeed() {
        tableData.forEach(el => {
            var tr_feeds = document.getElementsByName(el.feed_name);
            if(el.feed_display === 'none') {
                if(expandCollapseText === 'Expand All') {
                    tr_feeds.forEach(element => { element.style.display = 'revert' });
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                    setExpandCollapseText('Collapse All');
                }
            } else {
                if(expandCollapseText === 'Collapse All') {
                    tr_feeds.forEach(element => { element.style.display = 'none' });
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                    setExpandCollapseText('Expand All');
                }
            }
        });
    }

    async function openFeed(advertiser_name, feed_name) {
        let tr_feeds = document.getElementsByName(feed_name);
        tableData.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.advertiser_name === advertiser_name) {
                    tr_feeds.forEach(element => { element.style.display = 'revert' });
                    document.getElementsByName(advertiser_name)[0].className = "fa-solid fa-circle-minus";
                    el.feed_display = 'revert';
                }
            } else {
                if(el.advertiser_name === advertiser_name) {
                    tr_feeds.forEach(element => { element.style.display = 'none' });
                    document.getElementsByName(advertiser_name)[0].className = "fa-solid fa-circle-plus";
                    el.feed_display = 'none';
                }
            }
        });
    }

    async function openFeedCountry(country, feed_name) {
        let tr_feeds = document.getElementsByName(feed_name);
        tableData.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.country === country) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    document.getElementsByName(country)[0].className = "fa-solid fa-circle-minus";
                    el.feed_display = 'revert';
                }
            } else {
                if(el.country === country) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    document.getElementsByName(country)[0].className = "fa-solid fa-circle-plus";
                    el.feed_display = 'none';
                }
            }
        });
    }

    function changeDateRange(date, type) {
        const d = new Date(date).toISOString().split('T')[0];
        if(type === 'start') {
            setDateRangeStart(d);
        } else {
            setDateRangeEnd(d);
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function exportToCsv() {
        setExportToCsvButtonText('Exporting...');

        var headers = [];
        var usersCsv = [];
        var row = "";
        if(selectedReportType === 'channel') {
            // Headers for each column
            headers = ['channel, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr'];
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { channel, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push([channel, Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, impressions, searches, clicks, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])

            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'channel_report.csv', fileType: 'text/csv'})
        }

        if(selectedReportType === 'day') {
            // Headers for each column
            headers = ['date, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr'];
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { date, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push(['"' + date + '"', Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, impressions, searches, clicks, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])

            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'channel_report_day.csv', fileType: 'text/csv'})
            await delay(3000);

            headers = ['date, channel, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr'];
            usersCsv = [];
            for(const table of tableData) {
                row = "";
                var date = '"' + table.date + '"';
                for(const f of table.feeds) {
                    row = date + ',' + f.channel + ',' + Math.round(f.revenue*100)/100 + ',' + Math.round(f.pub_rev*100)/100 + ',' + f.impressions + ',' + f.searches + ',' + f.clicks + ',' + Math.round(f.rpm*100)/100 + ',' + Math.round(f.cpc*100)/100 + ',' + Math.round(f.ctr*100)/100;
                    usersCsv.push(row);
                }
            }

            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'channel_report_day_details.csv', fileType: 'text/csv'})
        }

        if(selectedReportType === 'country') {
            // Headers for each column
            headers = ['country, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr'];
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { country, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push([country, Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, impressions, searches, clicks, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])

            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'channel_report_country.csv', fileType: 'text/csv'})
            await delay(3000);

            headers = ['country, channel, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr'];
            usersCsv = [];
            for(const table of tableData) {
                row = "";
                var country = table.country;
                for(const f of table.feeds) {
                    row = country + ',' + f.channel + ',' + Math.round(f.revenue*100)/100 + ',' + Math.round(f.pub_rev*100)/100 + ',' + f.impressions + ',' + f.searches + ',' + f.clicks + ',' + Math.round(f.rpm*100)/100 + ',' + Math.round(f.cpc*100)/100 + ',' + Math.round(f.ctr*100)/100;
                    usersCsv.push(row);
                }
            }

            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'channel_report_country_details.csv', fileType: 'text/csv'})
        }

        setExportToCsvButtonText('Export to CSV');
    }

    async function sortTable(sort_by) {
        var tableDataTmp = [];
        if(sort_by === 'revenue') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.revenue < p2.revenue) ? 1 : (p1.revenue > p2.revenue) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.revenue > p2.revenue) ? 1 : (p1.revenue < p2.revenue) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'pub_rev') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.pub_rev < p2.pub_rev) ? 1 : (p1.pub_rev > p2.pub_rev) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.pub_rev > p2.pub_rev) ? 1 : (p1.pub_rev < p2.pub_rev) ? -1 : 0);
                setSortBy('asc')
            }
            
        }
        if(sort_by === 'impressions') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.impressions < p2.impressions) ? 1 : (p1.impressions > p2.impressions) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.impressions > p2.impressions) ? 1 : (p1.impressions < p2.impressions) ? -1 : 0);
                setSortBy('asc')
            }
            
        }
        if(sort_by === 'searches') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.searches < p2.searches) ? 1 : (p1.searches > p2.searches) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.searches > p2.searches) ? 1 : (p1.searches < p2.searches) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'clicks') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.clicks < p2.clicks) ? 1 : (p1.clicks > p2.clicks) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.clicks > p2.clicks) ? 1 : (p1.clicks < p2.clicks) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'rpm') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm < p2.rpm) ? 1 : (p1.rpm > p2.rpm) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm > p2.rpm) ? 1 : (p1.rpm < p2.rpm) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'rpm') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm < p2.rpm) ? 1 : (p1.rpm > p2.rpm) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm > p2.rpm) ? 1 : (p1.rpm < p2.rpm) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'cpc') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.cpc < p2.cpc) ? 1 : (p1.cpc > p2.cpc) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.cpc > p2.cpc) ? 1 : (p1.cpc < p2.cpc) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'ctr') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.ctr < p2.ctr) ? 1 : (p1.ctr > p2.ctr) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.ctr > p2.ctr) ? 1 : (p1.ctr < p2.ctr) ? -1 : 0);
                setSortBy('asc')
            }
        }
        setExportToCsvButtonText('Export to CSV ');
        setTableData(tableDataTmp);
        await delay(1000);
        setExportToCsvButtonText('Export to CSV');
    }

    function expandTable() {
        if(showTable === false) {
            setShowTable(true);
            setExpandCollapseTable('Collapse Table');
        } else {
            setShowTable(false);
            setExpandCollapseTable('Expand Table');
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Channel Details</h2>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Advertisers</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="All Advertisers"
                                        defaultValue={''}
                                        onChange={(event) => handleSelectAdv(event)}
                                        isMulti
                                        options={advOptionList}
                                        values={selectedAdvOptions}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Adv Feed</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="None"
                                        defaultValue={''}
                                        onChange={(event) => handleSelectFeed(event)}
                                        isMulti={false}
                                        options={feedOptionList}
                                        values={selectedFeedOptions}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                                
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Channels</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="All Channels"
                                        value={selectedChannelOptions}
                                        onChange={(event) => handleSelectChannel(event)}
                                        isMulti
                                        options={channelOptionList}
                                        values={selectedChannelOptions}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                                
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Date Range</label>
                                    <select value={selectedDateRangeOptions}  onChange={(event) => handleSelectDateRange(event.target.value)} className="form-control">
                                        <option value="yesterday">Yesterday</option>
                                        <option value="last_7days_minus_2">Last 7 days</option>
                                        <option value="last_14days">Last 14 days</option>
                                        <option value="last_30days">Last 30 days</option>
                                        <option value="month">MTD</option>
                                        <option value="previous_month">Previous Month</option>
                                        <option value="last_last_month">Last last Month</option>
                                        <option value="date_range">Custom Range</option>
                                    </select>
                                </div>
                            </div>

                            {selectedDateRangeOptions === "date_range" && (
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date Start</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'start')} value={dateRangeStart} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date End</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'end')} value={dateRangeEnd} className="form-control"/>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Report Type</label>
                                    <select value={selectedReportType}  onChange={(event) => handleSelectReportType(event.target.value)} className="form-control">
                                        <option value="channel">By Channel</option>
                                        <option value="day">By Day</option>
                                        <option value="country">By Country</option>
                                    </select>
                                </div>
                            </div>

                            {selectedFeedOptions.length > 0 && (
                                <Button className="me-2" variant="info" onClick={() => getAdvertiserReport()}>
                                    Search
                                </Button>
                            )}
                            {selectedFeedOptions.length === 0 && (
                                <Button className="me-2" variant="warning">
                                    Please, select one feed.
                                </Button>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            {loadingBadge === true && (
                <Badge bg="info">Loading</Badge>
            )}

            {tableDataTotal &&
                tableDataTotal.map((item, key) => (
                    <div className="card pt-3 pb-3" key={key+1100}>
                        <div className="row">
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Revenue
                                    <h3>${Intl.NumberFormat("en").format(item.revenue)}</h3>
                                    <span style={item.revenue_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.revenue_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.revenue_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Searches
                                    <h3>{Intl.NumberFormat("en").format(item.searches)}</h3>
                                    <span style={item.searches_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.searches_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.searches_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    Clicks
                                    <h3>{Intl.NumberFormat("en").format(item.clicks)}</h3>
                                    <span style={item.clicks_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.clicks_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.clicks_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    RPM
                                    <h3>${Intl.NumberFormat("en").format(item.rpm)}</h3>
                                    <span style={item.rpm_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.rpm_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.rpm_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    CPC
                                    <h3>${Intl.NumberFormat("en").format(item.cpc)}</h3>
                                    <span style={item.cpc_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.cpc_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.cpc_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    CTR
                                    <h3>{Intl.NumberFormat("en").format(item.ctr)}%</h3>
                                    <span style={item.ctr_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.ctr_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.ctr_percent)}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}

            {(chartData.length > 0 || chartDataset.length > 0) && (
                <div className="col-12">
                    <div className="card">
                        <div className="card-body pb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>Display By</label>
                                <select value={selectedParameter}  onChange={(event) => handleSelectParameter(event.target.value)} className="form-control">
                                    <option value="revenue">Revenue</option>
                                    <option value="pub_revenue">Pub Revenue</option>
                                    <option value="impressions">Impressions</option>
                                    <option value="searches">Searches</option>
                                    <option value="clicks">Clicks</option>
                                    <option value="rpm">RPM</option>
                                    <option value="cpc">CPC</option>
                                    <option value="ctr">CTR</option>
                                    {selectedReportType !== 'country' && (
                                        <option value="bs_rate_us">Blocked Search Rate (US Only)</option>
                                    )}
                                    {selectedReportType === 'country' && (
                                        <option value="bs_rate_us">Blocked Search Rate</option>
                                    )}
                                </select>
                            </div>

                            {selectedReportType === 'day' && (
                                <Bar data={chartDataDay} height={chartHeight} options={chartOptionsDay} />
                            )}
                            {selectedReportType === 'channel' && (
                                <Bar data={chartDataChannel} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                            )}
                            {selectedReportType === 'country' && (
                                <Bar data={chartDataMain} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                            )}

                        </div>

                    </div>
                </div>
            )}

            {(chartData.length > 0 || chartDataset.length > 0) && (
                <div className="col-12">
                    <div className="card">
                        <div className="card-body pb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>Display By</label>
                                <select value={selectedParameter2}  onChange={(event) => handleSelectParameter2(event.target.value)} className="form-control">
                                    <option value="revenue">Revenue</option>
                                    <option value="pub_revenue">Pub Revenue</option>
                                    <option value="impressions">Impressions</option>
                                    <option value="searches">Searches</option>
                                    <option value="clicks">Clicks</option>
                                    <option value="rpm">RPM</option>
                                    <option value="cpc">CPC</option>
                                    <option value="ctr">CTR</option>
                                    {selectedReportType !== 'country' && (
                                        <option value="bs_rate_us">Blocked Search Rate (US Only)</option>
                                    )}
                                    {selectedReportType === 'country' && (
                                        <option value="bs_rate_us">Blocked Search Rate</option>
                                    )}
                                </select>
                            </div>

                            {selectedReportType === 'day' && (
                                <Bar data={chartDataDay2} height={chartHeight} options={chartOptionsDay2} />
                            )}
                            {selectedReportType === 'channel' && (
                                <Bar data={chartDataChannel2} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                            )}
                            {selectedReportType === 'country' && (
                                <Bar data={chartDataMain2} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                            )}

                        </div>

                    </div>
                </div>
            )}

            {tableData.length > 0 &&
                <div style={{display: 'table', margin: '0px auto'}}>
                    <Button className="me-2 mb-4" variant="success" onClick={() => expandTable()}>
                        {expandCollapseTable}
                    </Button>
                </div>
            }

            {selectedReportType === 'day' && tableData.length > 0 && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">

                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv()}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Date</strong> <span style={{cursor: 'pointer'}} onClick={() => openAllFeed()}>({expandCollapseText})</span></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bs_rate_us')}>BS Rate (US Only)</strong></th>
                                        </tr>
                                    </thead>
                                    {tableData &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1200}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" name={item.advertiser_name} onClick={() => openFeed(item.advertiser_name, item.feed_name)}></i> {item.date}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                    <td>{Intl.NumberFormat("en").format(item.bs_rate_us)} %</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} key={key+1300} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.channel}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.impressions)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.searches)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.clicks)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.rpm)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.cpc)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.ctr)} %</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.bs_rate_us)} %</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}

                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1400}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                    <td>{Intl.NumberFormat("en").format(item.bs_rate_us)} %</td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectedReportType === 'channel' && tableData.length > 0 && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">

                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv()}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Channel</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bs_rate_us')}>BS RATE (US ONLY)</strong></th>
                                        </tr>
                                    </thead>
                                    {tableData &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1500}>
                                                <tr id={item.advertiser_id}>
                                                    <td>{item.channel} ({item.advertiser})</td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                    <td>{Intl.NumberFormat("en").format(item.bs_rate_us)} %</td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1600}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                    <td>{Intl.NumberFormat("en").format(item.bs_rate_us)} %</td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectedReportType === 'country' && tableData.length > 0 && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">

                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv()}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Country</strong> <span style={{cursor: 'pointer'}} onClick={() => openAllFeed()}>({expandCollapseText})</span></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bs_rate')}>BS Rate</strong></th>
                                        </tr>
                                    </thead>
                                    {tableData &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1700}>
                                                <tr id={item.advertiser_id}>
                                                    <td><i className="fa-solid fa-circle-plus" name={item.country} onClick={() => openFeedCountry(item.country, item.feed_name)}></i> {item.country}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                    <td>{Intl.NumberFormat("en").format(item.bs_rate)} %</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} key={key+1800} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.channel}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.impressions)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.searches)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.clicks)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.rpm)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.cpc)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.ctr)} %</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.bs_rate)} %</td>
                                                    </tr>
                                                    );
                                                })}

                                            </tbody>
                                        )
                                    )}

                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1900}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                    <td>{Intl.NumberFormat("en").format(item.bs_rate)} %</td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default ChannelDetails;