import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Alert, Modal } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import { useSearchParams, useNavigate } from "react-router-dom";
import DatePicker  from "react-datepicker";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const FeedNew = () => {
    let navigate = useNavigate();
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getFeed();
        getDataHistory();
        // eslint-disable-next-line
    }, []);

    const [state, dispatch] = useReducer(reducer, initial);
    const [searchParams] = useSearchParams();
    const [activeStatus, setActiveStatus] = useState('yes');
    const [refid, setRefid] = useState('');
    const [name, setName] = useState('');
    const [api, setAPI] = useState('');
    const [feedType, setFeedType] = useState('Desktop');
    const [campaignId, setCampaignId] = useState('');
    const [publisherId, setpublisherId] = useState('');
    const [publisherIdText, setpublisherIdText] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [advertiserList, setAdvertiserList] = useState([]);
    const [advertiserListStatus, setAdvertiserListStatus] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [tableDataHistory, setTableDataHistory] = useState([]);

    const [advFeedName, setAdvFeedName] = useState('');
    const [advFeedNameText, setAdvFeedNameText] = useState('');
    const [channelName, setChannelName] = useState('');
    const [allChannelList, setAllChannelList] = useState([]);
    const [showChannelList, setShowChannelList] = useState([]);
    const [showChannelListStatus, setShowChannelListStatus] = useState('');
    const [campaignServer, setCampaignServer] = useState('');
    const [advFeedNameList, setAdvFeedNameList] = useState([]);
    const [advFeedNameListStatus, setAdvFeedNameListStatus] = useState('');
    const [color, setColor] = useState('#ffffff');
    const [note, setNote] = useState('');

    function chooseChannel(name) {
        setChannelName(name);
        setShowChannelList([]);
        setShowChannelListStatus('')
    }

    function changeShowChannelList(name) {
        setShowChannelListStatus('')
        setShowChannelList([]);
        var selected_list = [];

        if(name !== '') {
            for(const channel_name of allChannelList) {
                var contains = channel_name.name.includes(name)
                if(contains === true) {
                    selected_list.push(channel_name);
                }
            }
            setShowChannelList(selected_list);
            if(selected_list.length === 0) {
                setShowChannelListStatus('no')
            }
        }
    }

    function handleChangeColor(color) {
        setColor(color.hex);
    };


    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function handleSelectFeedType(value) {
        setFeedType(value);
    }

    function changeRefid(event) {
        setRefid(event.target.value);
    }

    function changeName(event) {
        setName(event.target.value);
    }

    function changeAPI(event) {
        setAPI(event.target.value);
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    function choosepublisherId(id, name) {
        setpublisherId(id);
        const text = id+' '+name;
        setpublisherIdText(text);

        setAdvertiserList([]);
        setAdvertiserListStatus('');
    }

    async function changepublisherId(event) {
        setpublisherIdText(event.target.value);
        setpublisherId(event.target.value);
        
        const data = { search: event.target.value }
        const response = await axios.post(baseURL+'/api/publisher_search', data)
        setAdvertiserList(response.data.data);
        setAdvertiserListStatus(response.data.status);
    }

    async function changeAdvFeedName(event) {
        setAdvFeedName(event.target.value);
        setAdvFeedNameText(event.target.value);

        if(event.target.value !== "") {
            const data = { search: event.target.value }
            const response = await axios.post(baseURL+'/api/feed_search', data)
            setAdvFeedNameList(response.data.data);
            setAdvFeedNameListStatus(response.data.status);
        } else {
            setAdvFeedNameList([]);
            setAdvFeedNameListStatus('');
        }
    }

    function chooseAdvFeedName(id, name) {
        setAdvFeedName(name);
        const text = id+' '+name;
        setAdvFeedNameText(text);

        setAdvFeedNameList([]);
        setAdvFeedNameListStatus('');

        getFeedChannels(id);
    }
    
    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }

    async function saveFeed() {
        const data = {
            feed_id: searchParams.get("id"),
            active: activeStatus,
            publisher_id: publisherId,
            refid: refid,
            name: name,
            api: api,
            campaign_server: campaignServer,
            feed_type: feedType,
            adv_feed_name: advFeedName,
            entry_date: entryDate,
            color: color,
            note: note,
            channel_name: channelName,
        }
        const response = await axios.post(baseURL+'/api/campaign_edit', data)

        setAlertStatus(response.data.status);
        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
        if(response.data.status === 'update') {
            getDataHistory();
        }
    }

    async function getFeed() {
        const data = { feed_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/campaign_get', data)
        if(response.data.status === 'ok') {
            setName(response.data.data[0].name);
            setAPI(response.data.data[0].api);
            setCampaignServer(response.data.data[0].campaign_server);
            setFeedType(response.data.data[0].type);
            setCampaignId(searchParams.get("id"));
            setpublisherId(response.data.data[0].publisher_id);
            setEntryDate(response.data.data[0].entry_date);
            setpublisherIdText(response.data.data[0].publisher_id+' '+response.data.data[0].publisher_name);
            setAdvFeedName(response.data.data[0].feed_name);
            setAdvFeedNameText(response.data.data[0].feed_id+' '+response.data.data[0].feed_name);
            setColor(response.data.data[0].color);
            setRefid(response.data.data[0].refid);
            setNote(response.data.data[0].note);
            setChannelName(response.data.data[0].channel_name);
            getFeedChannels(response.data.data[0].feed_id);
        }
    }

    async function getFeedChannels(feed_id) {
        const data = { feed_id: feed_id }
        const response = await axios.post(baseURL+'/api/feed_channels_get', data)
        setAllChannelList(response.data.channel_list);
    }

    async function deleteFeed() {
        const data = { feedId: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/campaign_delete', data)
        if(response.data.status === 'ok') {
            navigate("/campaign-management?set_saved_options=yes");
        }
    }

    async function getDataHistory() {
        const data = { campaign_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/campaign_get_history', data)

        var result = [];
        for(const d of response.data.data) {
            var date = "";
            if(d.date_timestamp !== null) {
                date = await convertTimestampToDate(d.date_timestamp);
            }
            
            result.push({
                id: d.id,
                date: date,
                name: d.name,
                active: d.active,
                publisher_id: d.publisher_id,
                refid: d.refid,
                api: d.api,
                type: d.type,
                entry_date: d.entry_date,
                adv_feed_name: d.adv_feed_name,
                channel: d.channel,
            });
        }
        setTableDataHistory(result);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Edit Campaign</h2>
                <Link to={"/campaign-management?set_saved_options=yes"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Campaign ID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={campaignId} placeholder="Campaign ID" readOnly={true} />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Publisher ID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={publisherIdText} onChange={(event) => changepublisherId(event)} placeholder="Publisher ID"/>
                                                {advertiserList &&
                                                    advertiserList.map((item, key) => (
                                                        <li className='pt-1 pb-1' onClick={() => choosepublisherId(item.id, item.name)}>Publisher ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {advertiserListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no publisher with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">REFID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={refid} onChange={(event) => changeRefid(event)} placeholder="REFID"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Campaign Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={name} onChange={(event) => changeName(event)} placeholder="Campaign Name"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-api">Campaign API</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-api" value={api} onChange={(event) => changeAPI(event)} placeholder="Campaign API"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-campaign-server">Campaign Server</label>
                                        <div className="col-lg-6">
                                            <select value={campaignServer} onChange={(event) => setCampaignServer(event.target.value)} className="form-control" id="val-campaign-server" name="val-campaign-server">
                                                <option value="clarityseek.com">clarityseek.com</option>
                                                <option value="metasearch1.com">metasearch1.com</option>
                                                <option value="a.manage.clarityseek.com">a.manage.clarityseek.com</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-type">Campaign Type</label>
                                        <div className="col-lg-6">
                                            <select value={feedType} onChange={(event) => handleSelectFeedType(event.target.value)} className="form-control" id="feed-type">
                                                <option value="Desktop">Desktop</option>
                                                <option value="Mobile">Mobile</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-adv-feed-name">Adv Feed Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-adv-feed-name" value={advFeedNameText} onChange={(event) => changeAdvFeedName(event)} placeholder="Adv Feed Name"/>
                                                {advFeedNameList &&
                                                    advFeedNameList.map((item, key) => (
                                                        <li className='pt-1 pb-1' style={{cursor:'pointer'}} onClick={() => chooseAdvFeedName(item.id, item.name)}>Adv Feed ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {advFeedNameListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no feed with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-adv-feed-name">Channel</label>
                                        <div className="col-lg-6">

                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" value={channelName} onChange={(e) => {changeShowChannelList(e.target.value); setChannelName(e.target.value)}} placeholder="Channel ID" />
                                            </div>

                                            {showChannelList &&
                                                showChannelList.map((item, key) => (
                                                    <li className='pt-1 pb-1' style={{cursor: 'pointer'}} onClick={() => chooseChannel(item.name)}>{item.name}</li>
                                                )
                                            )}

                                            {showChannelListStatus === 'no' && (
                                                <li className='pt-1 pb-1'>There is no channel with this name.</li>
                                            )}
                                            
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Note</label>
                                        <div className="col-lg-6">
                                            <textarea rows={4} className="form-control" id="val-name" value={note} onChange={(event) => setNote(event.target.value)} placeholder="Note"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveFeed()}>
                                Save
                            </Button>

                            <Link to="/campaign-management?set_saved_options=yes" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            <Button variant="danger" className="" onClick={() => setBasicModal(true)}>
                                Delete
                            </Button>

                            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                <Modal.Header>
                                    <h4 className="text-black mb-2">&nbsp;</h4>
                                    <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-black">Are you sure to delete the campaign?</h4>
                                    <Button variant="danger" className="" onClick={() => deleteFeed()}>
                                        Delete
                                    </Button>
                                </Modal.Body>
                            </Modal>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'update' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The data has been updated successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header border-0 flex-wrap pb-0 select-btn">
                            <div className="mb-1">
                                <h4 className="fs-20 text-black">Update History</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <Table responsive className="header-border respon-table-data" hover>
                                <thead>
                                    <tr>
                                        <th><strong>Date</strong></th>
                                        <th><strong>Active</strong></th>
                                        <th><strong>Publisher ID</strong></th>
                                        <th><strong>REFID</strong></th>
                                        <th><strong>Campaign Name</strong></th>
                                        <th><strong>Campaign API</strong></th>
                                        <th><strong>Campaign Type</strong></th>
                                        <th><strong>ADV Feed Name</strong></th>
                                        <th><strong>Channel</strong></th>
                                        <th><strong>Entry Date</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableDataHistory.length > 0 &&
                                        tableDataHistory.map((item, key) => (
                                            <tr>
                                                <td>{item.date}</td>
                                                <td>{item.active === 1 ? 'Active' : 'Inactive'}</td>
                                                <td>{item.publisher_id}</td>
                                                <td>{item.refid}</td>
                                                <td>{item.name}</td>
                                                <td className='text-truncate' style={{maxWidth: '200px'}}>{item.api}</td>
                                                <td>
                                                    {item.type === 'Mobile' && (
                                                        <span><i className="fa fa-mobile-screen"></i> Mobile</span>
                                                    )}
                                                    {item.type === 'Desktop' && (
                                                        <span><i className="fa fa-computer"></i> Desktop</span>
                                                    )}
                                                </td>
                                                <td>{item.adv_feed_name}</td>
                                                <td>{item.channel}</td>
                                                <td>{item.entry_date}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default FeedNew;